import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { GalleryComponent, GalleryItem } from '@daelmaak/ngx-gallery';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { GalleriaModule } from 'primeng/galleria';
import { Subject, takeUntil } from 'rxjs';
import { PriceTagComponent } from '../../amm-share/components/price-tag/price-tag.component';
import { VariationGroupComponent } from '../../amm-share/components/variation-group/variation-group.component';
import { forceLogin } from '../../amm-share/decorators';
import { GalleryConfig } from '../../amm-share/models';
import {
  AmmPrice,
  Image,
  Product,
  VariantCombinationBackend,
  VariantSelectionBackend,
} from '../../amm-share/models/catalog.model';
import {
  ProductService,
  VariantSelectionService,
} from '../../amm-share/services';
import { ShoppingInfoStore } from '../../amm-share/stores';
import { AmmQtyAdjusterComponent } from './../../amm-share/components/amm-qty-adjuster/amm-qty-adjuster.component';
import { AuthService } from './../../amm-share/services/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'amm-product-quick-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    VariationGroupComponent,
    AmmQtyAdjusterComponent,
    GalleriaModule,
    PriceTagComponent,
    GalleryComponent,
  ],
  templateUrl: './product-quick-view.component.html',
  styleUrl: './product-quick-view.component.scss',
})
export class ProductQuickViewComponent implements OnInit, OnDestroy {
  public product: Product | undefined;
  public medias!: GalleryItem[];
  galleryConfig: GalleryConfig = {
    arrows: true,
    descriptions: false,
    mouseGestures: true,
    touchGestures: true,
    counter: false,
    counterOrientation: 'bottom',
    visibleItems: 1,
    objectFit: 'cover',
    loading: 'lazy',
    loop: true,
    rtl: false,
    selectedIndex: 0,
    thumbs: true,
    thumbsAutoScroll: true,
    thumbsOrientation: 'bottom',
    thumbsArrows: false,
    thumbsArrowSlideByLength: 0,
    thumbsScrollBehavior: 'smooth',
  };
  public variantCombination: VariantCombinationBackend | null = null;
  public shopQty: number = 0;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  public lowestPrice: AmmPrice = {
    currency: 'USD',
    sign: '$',
    amount: 0,
  };
  public vairants: VariantSelectionBackend | null = null;
  public isVariantSelected: boolean = false;
  public mainProduct: Product | undefined = undefined;

  public shoppingCartInfoStore = inject(ShoppingInfoStore);
  private router: Router = inject(Router);
  private dialogConfig = inject(DynamicDialogConfig<ProductQuickViewComponent>);
  private dialogRef = inject(DynamicDialogRef<ProductQuickViewComponent>);
  private dialogService = inject(DialogService);
  private productService = inject(ProductService);
  private authService = inject(AuthService);
  protected variantSelectService = inject(VariantSelectionService);
  private messageService = inject(MessageService);
  private destory$: Subject<boolean> = new Subject<boolean>();
  private familyCode: string = '';

  private mainProductMedias: GalleryItem[] = [];

  ngOnInit(): void {
    this.product = this.dialogConfig?.data?.product;
    this.mainProduct = this.product;
    this.familyCode = this.product?.code || '';
    this.isVariantSelected = false;
    this.variantSelectService.resetMaps();
    this.productService
      .getProductsByFamilyCode(this.product?.code || '')
      .subscribe((product: Product) => {
        if (Object.keys(product).length > 0) {
          const variantProduct = product;
          this.mainProduct = product;
          // Handle the available variant selection
          this.variantCombination =
            variantProduct.variantCombinationMap || null;

          // Handle the selection order
          if (this.variantCombination) {
            Object.keys(this.variantCombination).forEach((x, idx) => {
              this.variantSelectService.variantTypeOrder.set(x, idx);
            });
          }

          // Handle the lowest price which showed on UI as
          // a product has variant;
          this.lowestPrice = variantProduct.variantMinPrice || {
            currency: 'USD',
            sign: '$',
            amount: 0,
          };
          // Handle the variants product
          this.vairants = variantProduct.variants || null;

          // Handle variant rotate imgages
          if (variantProduct.mainImage) {
            const imageCollections: Image[] = [variantProduct.mainImage];
            if (variantProduct.variants) {
              const values = Object.values(variantProduct.variants);

              values.forEach((x) => {
                if (x.mainImage) {
                  imageCollections.push(x.mainImage);
                }
                if (x.merchImages && x.merchImages.length > 0) {
                  imageCollections.concat(x.merchImages);
                }
              });
            }
            // Convert the image to the ngx gallery format
            this.medias = imageCollections.map(
              (x) =>
                <GalleryItem>{
                  src: x.uri,
                  thumbSrc: x.uri,
                },
            );
            this.mainProductMedias = this.medias;
          }
        }
      });
    this.variantSelectService.variantOnSelection$
      .pipe(takeUntil(this.destory$))
      .subscribe(() => {
        const currentVariantSelection =
          this.variantSelectService.getFormattedSelection();
        // Check if all the variant type get selected
        const isAllTypeSelected =
          this.variantSelectService.getFormattedSelection()?.split('|')
            ?.length == this.variantSelectService.variantTypeOrder.size;

        // If variant selection exists we should use the selection price
        // otherwise we should use the lowest price
        if (currentVariantSelection && isAllTypeSelected && this.vairants) {
          if (this.vairants[currentVariantSelection]) {
            this.product = this.vairants[currentVariantSelection];
            this.medias = [
              <GalleryItem>{
                src: this.product.mainImage?.uri || '',
                thumbSrc: this.product.mainImage?.uri || '',
              },
            ];
            if (this.product.merchImages) {
              this.medias = this.medias.concat(
                this.product.merchImages?.map(
                  (x) =>
                    <GalleryItem>{
                      src: x.uri,
                      thumbSrc: x.uri,
                    },
                ) || [],
              );
            }
          } else {
            this.medias = this.mainProductMedias;
            this.product = void 0;
          }
          this.isVariantSelected = true;
          this.shopQty = 1;
        } else {
          this.isVariantSelected = false;
          this.medias = this.mainProductMedias;
          this.shopQty = 0;
        }
      });
  }
  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }
  onViewDetailClick() {
    const variantSelectionObj =
      this.variantSelectService.getSelectionAsObject();
    this.router
      .navigate([
        'home',
        {
          outlets: {
            mainBody: ['details', this.familyCode, variantSelectionObj],
          },
        },
      ])
      .then(() => {
        this.dialogRef.close();
        this.dialogService.dialogComponentRefMap.forEach((x) => x.destroy());
      });
  }
  onAddToCartClick() {
    // call to add the to shopping cart
    if (this.product) {
      this.shoppingCartInfoStore.setVariantShoppingCart(
        this.shopQty,
        this.product.code || '',
      );
      this.messageService.add({
        severity: 'contrast',
        detail: 'Added to ShopCart',
        key: 'product-operation-toast',
      });

    }
  }
  onIncrease(qty: number) {
    if (
      (this.product?.inventory && this.shopQty > this.product?.inventory) ||
      !this.isVariantSelected
    ) {
      return;
    }
    this.shopQty++;
  }
  onDecrease(qty: number) {
    if (this.shopQty <= 1 && this.isVariantSelected) {
      return;
    }
    this.shopQty--;
  }

  onAddToFavoriteClick() {
    this.AddFavorite(this.authService, this.dialogService);
  }
  @forceLogin()
  AddFavorite(authService: AuthService, dialogService: DialogService) {
    if (this.product) {
      this.shoppingCartInfoStore.increaseFavorite(this.product?.code || '');
    }
  }

  getCurrentQty() {
    return (
      this.shoppingCartInfoStore
        .shoppingCarts()
        .find((x) => x.code === this.product?.code)?.qty || 0
    );
  }
}
