import { PageRegisterComponent } from '../../amm-pages/page-register/page-register.component';

export function forceLogin() {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    const originValue = descriptor.value;

    descriptor.value = function (...args: any[]) {
      if (args[0].isLoggedIn()) {
        originValue.apply(this, args);
      } else {
        args[1].open(PageRegisterComponent, {
          showHeader: false,
          width: '760px',
          style: {
            padding: '0px',
            'border-radius': '24px',
            width: '760px',
            'min-height': '532px',
          },
          contentStyle: {
            overflow: 'visible',
            padding: '0px',
            'border-radius': '24px',
            width: '760px',
            'min-height': '532px',
          },
          breakpoints: {
            '760px': '95vw',
          },
        });
      }
    };
  };
}
