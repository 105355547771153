<div class="flex flex-row h-full quick-product-view-body">
  <div class="pic-gallary-container h-full">
    <gallery
      [items]="medias"
      [counter]="galleryConfig.counter"
      [loop]="galleryConfig.loop"
      [thumbsArrows]="galleryConfig.thumbsArrows"
      [thumbsAutoScroll]="galleryConfig.thumbsAutoScroll"
    ></gallery>
  </div>
  <div class="flex flex-col right-section h-full">
    <div class="flex flex-col variant-section">
      <div class="flex flex-col product-header">
        <div>
          {{ product?.title || mainProduct?.title }}
        </div>
        <div>
          {{ product?.store?.storeName || mainProduct?.store?.storeName }}
        </div>
      </div>
      <div>
        <amm-variation-group
          [variantCombination]="variantCombination"
        ></amm-variation-group>
      </div>
    </div>
    <div class="flex flex-col action-section">
      <div class="flex flex-row gap-4 price-section">
        @if (!isVariantSelected) {
          <amm-price-tag
            [price]="lowestPrice.amount || 0"
            [currencySign]="lowestPrice.sign || '$'"
          ></amm-price-tag>
        } @else {
          <!-- {{ product?.inventory }} -->
          <amm-price-tag
            [price]="product?.curPrice?.amount || 0"
            [currencySign]="product?.curPrice?.sign || '$'"
            [selected]="isVariantSelected"
            [hasInventory]="(product?.inventory || 0) > 0"
            [isOutOfStock]="(product?.inventory || 0) == 0"
            [isNoLongerAvailable]="product?.status === 'INACTIVE'"
          ></amm-price-tag>
        }
        <div
          class="flex gap-1 items-center view-detail-btn ml-auto cursor-pointer"
          (click)="onViewDetailClick()"
        >
          <span>View detail</span>
          <span class="material-symbols-outlined"> arrow_forward </span>
        </div>
      </div>
      <div class="flex flex-row gap-2 cart-operation-section">
        <!--
          Check if the product combination exists or not
          if product exists then add to cart
          otherwise show add to favorite button
        -->
        @if (
          isVariantSelected &&
          ((product?.inventory || 0) == 0 || product?.status === "INACTIVE")
        ) {
          <div
            class="add-to-cart-btn cursor-pointer"
            (click)="onAddToFavoriteClick()"
          >
            <span class="material-symbols-outlined"> favorite </span>
            <span>Add to favorite</span>
          </div>
        } @else {
          <div class="qty-adjuster-wrapper">
            <amm-qty-adjuster
              [showTitleIcon]="false"
              [shopQty]="shopQty"
              (onIncrease)="onIncrease($event)"
              (onDecrease)="onDecrease($event)"
              [min]="shopQty <= 1 && isVariantSelected ? 1 : 0"
              [max]="product?.inventory || 0"
            ></amm-qty-adjuster>
          </div>
          <button
            [disabled]="!isVariantSelected"
            class="add-to-cart-btn cursor-pointer"
            (click)="onAddToCartClick()"
          >
            Add to cart
          </button>
        }
      </div>
    </div>
  </div>
</div>
