<div
  class="flex flex-row gap-1 justify-center items-center quick-action-add-shopping-cart"
>
  @if (showTitleIcon()) {
    <span class="material-symbols-outlined !text-xl text-black">{{
      titleIcon()
    }}</span>
  }
  <div class="remove-button cursor-pointer">
    <button
      class="qty-button"
      [disabled]="shopQty <= min()"
      (click)="onQtyDecrease($event)"
    >
      <span class="material-symbols-outlined text-black">
        {{ decreaseIcon() }}
      </span>
    </button>
  </div>
  <div class="qty-input text-black">
    <input
      style="width: 30px; text-align: center"
      readonly
      type="number"
      min="0"
      [(ngModel)]="shopQty"
    />
  </div>
  <div class="remove-button cursor-pointer">
    <button
      [disabled]="shopQty >= Math.min(max(), maxLimit())"
      class="qty-button"
      (click)="onQtyIncrease($event)"
    >
      <span class="material-symbols-outlined text-black">{{
        increaseIcon()
      }}</span>
    </button>
  </div>
</div>
