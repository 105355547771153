import { Component, Input, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'amm-qty-adjuster',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './amm-qty-adjuster.component.html',
  styleUrl: './amm-qty-adjuster.component.scss',
})
export class AmmQtyAdjusterComponent {
  @Input()
  public shopQty: number = 0;
  public max = input(99);
  public min = input(1);
  public maxLimit = input(99);
  public showTitleIcon = input(true);
  public titleIcon = input('shopping_cart', {
    alias: 'titleIcon',
  });
  public decreaseIcon = input('remove', {
    alias: 'decreaseIcon',
  });
  public increaseIcon = input('add', {
    alias: 'increaseIcon',
  });

  public onIncrease = output<number>({
    alias: 'onIncrease',
  });
  public onDecrease = output<number>({
    alias: 'onDecrease',
  });
  public Math = Math;

  onQtyIncrease(event: PointerEvent | MouseEvent) {
    event.stopImmediatePropagation();
    this.onIncrease.emit(this.shopQty);
  }
  onQtyDecrease(event: PointerEvent | MouseEvent) {
    event.stopImmediatePropagation();
    this.onDecrease.emit(this.shopQty);
  }
}
