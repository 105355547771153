import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { VariantCombinationBackend } from '../../models/catalog.model';
import { VariantSelectionService } from '../../services';

@Component({
  selector: 'amm-variation-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './variation-group.component.html',
  styleUrl: './variation-group.component.scss',
})
export class VariationGroupComponent {
  public variantCombination = input<VariantCombinationBackend | null>();

  protected variantSelectService = inject(VariantSelectionService);

  onVariantSelection(type: string, value: string) {
    // Emit out selection
    const currentSelection =
      this.variantSelectService.variantSelection.get(type);
    if (currentSelection === value) {
      this.variantSelectService.variantSelection.set(type, '');
    } else {
      this.variantSelectService.variantSelection.set(type, value);
    }

    this.variantSelectService.variantOnSelection$.next(true);
  }
  getKeys(variantCombination: VariantCombinationBackend | null | undefined) {
    if (variantCombination) {
      return Object.keys(variantCombination);
    }
    return [];
  }
  getValues(
    variantCombination: VariantCombinationBackend | null | undefined,
    key: string,
  ) {
    if (variantCombination) {
      return variantCombination[key];
    }
    return [];
  }
}
