import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'amm-price-tag',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './price-tag.component.html',
  styleUrl: './price-tag.component.scss',
})
export class PriceTagComponent {
  public hasInventory = input(true);
  public isOutOfStock = input(false);
  public showVariantText = input(true);

  public isNoLongerAvailable = input(false);

  public currencySign = input('$');

  public price = input(0);

  public hasInventoryText = input('Select variant to view price');
  public outOfStockText = input('Out of stock');
  public noLongerAvailableText = input('No longer available');

  public vairantIndicator = input('+');

  public priceFormat = input('1.2-2');
  public selected = input(false);
  public onVariantSelectedText = input(
    'Shipping and taxes to be calculated at checkout.',
  );
}
